import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>Skills</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Trained Only</span>
    </h2>
    <h5>DECIPHER SCRIPT (INT; TRAINED ONLY)</h5>
    <p className="initial"><b>Check</b>: You can decipher writing in an
unfamiliar language or a message written in an incomplete or archaic
form. The base DC is 20 for the simplest messages, 25 for standard
texts, and 30 or higher for intricate, exotic, or very old writing.</p>
    <p>If the check succeeds, you understand the general content of a
piece of writing about one page long (or the equivalent). If the check
fails, make a DC 5 Wisdom check to see if you avoid drawing a false
conclusion about the text. (Success means that you do not draw a false
conclusion; failure means that you do.)</p>
    <p>Both the Decipher Script check and (if necessary) the Wisdom
check are made secretly, so that you can&#8217;t tell whether the conclusion
you draw is true or false.</p>
    <p><b>Action</b>: Deciphering the equivalent of a single page of
script takes 1 minute (ten consecutive full-round actions).</p>
    <p><b>Try Again</b>: No.</p>
    <p><b>Special</b>: A character with the <a href="featsAll.html#diligent" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diligent</a>
feat gets a +2 bonus on Decipher Script checks.</p>
    <p><b>Synergy</b>: If you have 5 or more ranks in Decipher
Script, you get a +2 bonus on <a href="skillsAll.html#use-magic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Magic Device</a> checks
involving scrolls.</p>
    <h5>DISABLE DEVICE (INT; TRAINED ONLY)</h5>
    <p className="initial"><b>Check</b>: The Disable Device check is made
secretly, so that you don&#8217;t necessarily know whether you&#8217;ve succeeded.
The DC depends on how tricky the device is. Disabling (or rigging or
jamming) a fairly simple device has a DC of 10; more intricate and
complex devices have higher DCs.</p>
    <p>If the check succeeds, you disable the device. If it fails by
4 or less, you have failed but can try again. If you fail by 5 or more,
something goes wrong. If the device is a trap, you spring it. If you&#8217;re
attempting some sort of sabotage, you think the device is disabled, but
it still works normally.</p>
    <p>You also can rig simple devices such as saddles or wagon
wheels to work normally for a while and then fail or fall off some time
later (usually after 1d4 rounds or minutes of use).</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "70px"
          }}>Device</th>
          <th style={{
            "width": "80px"
          }}>Time</th>
          <th style={{
            "width": "140px"
          }}>Disable Device DC<sup>1</sup></th>
          <th>Example</th>
        </tr>
        <tr className="odd-row">
          <td>Simple</td>
          <td>1 round</td>
          <td>10</td>
          <td>Jam a lock</td>
        </tr>
        <tr>
          <td>Tricky</td>
          <td>1d4 rounds</td>
          <td>15</td>
          <td>Sabotage a wagon wheel</td>
        </tr>
        <tr className="odd-row">
          <td>Difficult</td>
          <td>2d4 rounds</td>
          <td>20</td>
          <td>Disarm a trap, reset a trap</td>
        </tr>
        <tr>
          <td className="last-row">Wicked</td>
          <td className="last-row">2d4 rounds</td>
          <td className="last-row">25</td>
          <td className="last-row">Disarm a complex trap, cleverly
sabotage a clockwork device</td>
        </tr>
        <tr>
          <td colSpan="4">1 <i>If you attempt to leave behind no
trace of your tampering, add 5 to the DC.</i></td>
        </tr>
      </tbody>
    </table>
    <p><b>Action</b>: The amount of time needed to make a Disable
Device check depends on the task, as noted above. Disabling a simple
device takes 1 round and is a full-round action. An intricate or
complex device requires 1d4 or 2d4 rounds.</p>
    <p><b>Try Again</b>: Varies. You can retry if you have missed the
check by 4 or less, though you must be aware that you have failed in
order to try again.</p>
    <p><b>Special</b>: If you have the <a href="featsAll.html#nimble-fingers" style={{
        "color": "rgb(87, 158, 182)"
      }}>Nimble
Fingers</a> feat, you get a +2 bonus on Disable Device checks.</p>
    <p>A rogue who beats a trap&#8217;s DC by 10 or more can study the
trap, figure out how it works, and bypass it (along with her
companions) without disarming it.</p>
    <p><b>Restriction</b>: Rogues (and other characters with the
trapfinding class feature) can disarm magic traps. A magic trap
generally has a DC of 25 + the spell level of the magic used to create
it.</p>
    <p>The spells fire trap, glyph of warding, symbol, and
teleportation circle also create traps that a rogue can disarm with a
successful Disable Device check. Spike growth and spike stones,
however, create magic traps against which Disable Device checks do not
succeed. See the individual spell descriptions for details.</p>
    <h6>Other Ways to Beat a Trap</h6>
    <p className="initial">It&#8217;s possible to ruin many traps without
making a Disable Device check.</p>
    <p><b>Ranged Attack Traps</b>: Once a trap&#8217;s location is known,
the obvious way to ruin it is to smash the mechanism&#8212;assuming the
mechanism can be accessed. Failing that, it&#8217;s possible to plug up the
holes from which the projectiles emerge. Doing this prevents the trap
from firing unless its ammunition does enough damage to break through
the plugs.</p>
    <p><b>Melee Attack Traps</b>: These devices can be thwarted by
smashing the mechanism or blocking the weapons, as noted above.
Alternatively, if a character studies the trap as it triggers, he might
be able to time his dodges just right to avoid damage. A character who
is doing nothing but studying a trap when it first goes off gains a +4
dodge bonus against its attacks if it is triggered again within the
next minute.</p>
    <p><b>Pits</b>: Disabling a pit trap generally ruins only the
trapdoor, making it an uncovered pit. Filling in the pit or building a
makeshift bridge across it is an application of manual labor, not the
Disable Device skill. Characters could neutralize any spikes at the
bottom of a pit by attacking them&#8212;they break just as daggers do.</p>
    <p><b>Magic Traps</b>: Dispel magic helps here. Someone who
succeeds on a caster level check against the level of the trap&#8217;s
creator suppresses the trap for 1d4 rounds. This works only with a
targeted dispel magic, not the area version (see the spell description).</p>
    <h5>HANDLE ANIMAL (CHA; TRAINED ONLY)</h5>
    <p className="initial"><b>Check</b>: The DC depends on what you are
trying to do.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "250px"
          }}>Task</th>
          <th>Handle Animal DC</th>
        </tr>
        <tr className="odd-row">
          <td>Handle an animal</td>
          <td>10</td>
        </tr>
        <tr>
          <td>&#8220;Push&#8221; an animal</td>
          <td>25</td>
        </tr>
        <tr className="odd-row">
          <td>Teach an animal a trick</td>
          <td>15 or 20<sup>1</sup></td>
        </tr>
        <tr>
          <td>Train an animal for a general purpose</td>
          <td>15 or 20<sup>1</sup></td>
        </tr>
        <tr className="odd-row">
          <td>Rear a wild animal</td>
          <td>15 + HD of animal</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>See the specific trick or purpose
below.</i></td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "marginTop": "0.5em"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>General Purpose</th>
          <th style={{
            "width": "50px"
          }}>DC</th>
          <th style={{
            "width": "125px"
          }}>General Purpose</th>
          <th style={{
            "width": "50px"
          }}>DC</th>
        </tr>
        <tr className="odd-row">
          <td>Combat riding</td>
          <td>20</td>
          <td>Hunting</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Fighting</td>
          <td>20</td>
          <td>Performance</td>
          <td>15</td>
        </tr>
        <tr className="odd-row">
          <td>Guarding</td>
          <td>20</td>
          <td>Riding</td>
          <td>15</td>
        </tr>
        <tr>
          <td className="last-row">Heavy labor</td>
          <td className="last-row">15</td>
          <td className="last-row">&nbsp;</td>
          <td className="last-row">&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <p><i>Handle an Animal</i>: This task involves commanding an
animal to perform a task or trick that it knows. If the animal is
wounded or has taken any nonlethal damage or ability score damage, the
DC increases by 2. If your check succeeds, the animal performs the task
or trick on its next action.</p>
    <p><i>&#8220;Push&#8221; an Animal</i>: To push an animal means to get it to
perform a task or trick that it doesn&#8217;t know but is physically capable
of performing. This category also covers making an animal perform a
forced march or forcing it to hustle for more than 1 hour between sleep
cycles. If the animal is wounded or has taken any nonlethal damage or
ability score damage, the DC increases by 2. If your check succeeds,
the animal performs the task or trick on its next action.</p>
    <p><i>Teach an Animal a Trick</i>: You can teach an animal a
specific trick with one week of work and a successful Handle Animal
check against the indicated DC. An animal with an Intelligence score of
1 can learn a maximum of three tricks, while an animal with an
Intelligence score of 2 can learn a maximum of six tricks. Possible
tricks (and their associated DCs) include, but are not necessarily
limited to, the following.</p>
    <p>Attack (DC 20): The animal attacks apparent enemies. You may
point to a particular creature that you wish the animal to attack, and
it will comply if able. Normally, an animal will attack only humanoids,
monstrous humanoids, giants, or other animals. Teaching an animal to
attack all creatures (including such unnatural creatures as undead and
aberrations) counts as two tricks.</p>
    <p>Come (DC 15): The animal comes to you, even if it normally
would not do so.</p>
    <p>Defend (DC 20): The animal defends you (or is ready to defend
you if no threat is present), even without any command being given.
Alternatively, you can command the animal to defend a specific other
character.</p>
    <p>Down (DC 15): The animal breaks off from combat or otherwise
backs down. An animal that doesn&#8217;t know this trick continues to fight
until it must flee (due to injury, a fear effect, or the like) or its
opponent is defeated.</p>
    <p>Fetch (DC 15): The animal goes and gets something. If you do
not point out a specific item, the animal fetches some random object.</p>
    <p>Guard (DC 20): The animal stays in place and prevents others
from approaching.</p>
    <p>Heel (DC 15): The animal follows you closely, even to places
where it normally wouldn&#8217;t go.</p>
    <p>Perform (DC 15): The animal performs a variety of simple
tricks, such as sitting up, rolling over, roaring or barking, and so on.</p>
    <p>Seek (DC 15): The animal moves into an area and looks around
for anything that is obviously alive or animate.</p>
    <p>Stay (DC 15): The animal stays in place, waiting for you to
return. It does not challenge other creatures that come by,</p>
    <p>though it still defends itself if it needs to.</p>
    <p>Track (DC 20): The animal tracks the scent presented to it.
(This requires the animal to have the scent ability)</p>
    <p>Work (DC 15): The animal pulls or pushes a medium or heavy
load.</p>
    <p><i>Train an Animal for a Purpose</i>: Rather than teaching an
animal individual tricks, you can simply train it for a general
purpose. Essentially, an animal&#8217;s purpose represents a preselected set
of known tricks that fit into a common scheme, such as guarding or
heavy labor. The animal must meet all the normal prerequisites for all
tricks included in the training package. If the package includes more
than three tricks, the animal must have an Intelligence score of 2.</p>
    <p>An animal can be trained for only one general purpose, though
if the creature is capable of learning additional tricks (above and
beyond those included in its general purpose), it may do so. Training
an animal for a purpose requires fewer checks than teaching individual
tricks does, but no less time. </p>
    <p>Combat Riding (DC 20): An animal trained to bear a rider into
combat knows the tricks attack, come, defend, down, guard, and heel.
Training an animal for combat riding takes six weeks. You may also
&#8220;upgrade&#8221; an animal trained for riding to one trained for combat riding
by spending three weeks and making a successful DC 20 Handle Animal
check. The new general purpose and tricks completely replace the
animal&#8217;s previous purpose and any tricks it once knew. Warhorses and
riding dogs are already trained to bear riders into combat, and they
don&#8217;t require any additional training for this purpose.</p>
    <p>Fighting (DC 20): An animal trained to engage in combat knows
the tricks attack, down, and stay. Training an animal for fighting
takes three weeks.</p>
    <p>Guarding (DC 20): An animal trained to guard knows the tricks
attack, defend, down, and guard. Training an animal for guarding takes
four weeks.</p>
    <p>Heavy Labor (DC 15): An animal trained for heavy labor knows
the tricks come and work. Training an animal for heavy labor takes two
weeks.</p>
    <p>Hunting (DC 20): An animal trained for hunting knows the
tricks attack, down, fetch, heel, seek, and track. Training an animal
for hunting takes six weeks.</p>
    <p>Performance (DC 15): An animal trained for performance knows
the tricks come, fetch, heel, perform, and stay. Training an animal for
performance takes five weeks.</p>
    <p>Riding (DC 15): An animal trained to bear a rider knows the
tricks come, heel, and stay. Training an animal for riding takes three
weeks.</p>
    <p><i>Rear a Wild Animal</i>: To rear an animal means to raise a
wild creature from infancy so that it becomes domesticated. A handler
can rear as many as three creatures of the same kind at once. A
successfully domesticated animal can be taught tricks at the same time
it&#8217;s being raised, or it can be taught as a domesticated animal later.</p>
    <p><b>Action</b>: Varies. Handling an animal is a move action,
while pushing an animal is a full-round action. (A druid or ranger can
handle her animal companion as a free action or push it as a move
action.) For tasks with specific time frames noted above, you must
spend half this time (at the rate of 3 hours per day per animal being
handled) working toward completion of the task before you attempt the
Handle Animal check. If the check fails, your attempt to teach, rear,
or train the animal fails and you need not complete the teaching,
rearing, or training time. If the check succeeds, you must invest the
remainder of the time to complete the teaching, rearing, or training.
If the time is interrupted or the task is not followed through to
completion, the attempt to teach, rear, or train the animal
automatically fails.</p>
    <p><b>Try Again</b>: Yes, except for rearing an animal.</p>
    <p><b>Special</b>: You can use this skill on a creature with an
Intelligence score of 1 or 2 that is not an animal, but the DC of any
such check increases by 5. Such creatures have the same limit on tricks
known as animals do.</p>
    <p>A druid or ranger gains a +4 circumstance bonus on Handle
Animal checks involving her animal companion. In addition, a druid&#8217;s or
ranger&#8217;s animal companion knows one or more bonus tricks, which don&#8217;t
count against the normal limit on tricks known and don&#8217;t require any
training time or Handle Animal checks to teach.</p>
    <p>If you have the <a href="featsAll.html#animal-affinity" style={{
        "color": "rgb(87, 158, 182)"
      }}>Animal Affinity</a> feat, you get a
+2 bonus on Handle Animal checks.</p>
    <p><b>Synergy</b>: If you have 5 or more ranks in Handle Animal,
you get a +2 bonus on <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a> checks and wild empathy
checks.</p>
    <p><b>Untrained</b>: If you have no ranks in Handle Animal, you
can use a Charisma check to handle and push domestic animals, but you
can&#8217;t teach, rear, or train animals. A druid or ranger with no ranks in
Handle Animal can use a Charisma check to handle and push her animal
companion, but she can&#8217;t teach, rear, or train other nondomestic
animals.</p>
    <h5>KNOWLEDGE (INT; TRAINED ONLY)</h5>
    <p className="initial">Like the Craft and Profession skills,
Knowledge actually encompasses a number of unrelated skills. Knowledge
represents a study of some body of lore, possibly an academic or even
scientific discipline.</p>
    <p>Below are listed typical fields of study.</p>
    <ul>
      <li>Arcana (ancient mysteries, magic traditions, arcane
symbols, cryptic phrases, constructs, dragons, magical beasts)</li>
      <li>Architecture and engineering (buildings, aqueducts,
bridges, fortifications)</li>
      <li>Dungeoneering (aberrations, caverns, oozes, spelunking)</li>
      <li>Geography (lands, terrain, climate, people)</li>
      <li>History (royalty, wars, colonies, migrations, founding of
cities)</li>
      <li>Local (legends, personalities, inhabitants, laws, customs,
traditions, humanoids)</li>
      <li>Nature (animals, fey, giants, monstrous humanoids, plants,
seasons and cycles, weather, vermin)</li>
      <li>Nobility and royalty (lineages, heraldry, family trees,
mottoes, personalities)</li>
      <li>Religion (gods and goddesses, mythic history, ecclesiastic
tradition, holy symbols, undead)</li>
      <li>The planes (the Inner Planes, the Outer Planes, the Astral
Plane, the Ethereal Plane, outsiders, elementals, magic related to the
planes)</li>
    </ul>
    <p><b>Check</b>: Answering a question within your field of study
has a DC of 10 (for really easy questions), 15 (for basic questions),
or 20 to 30 (for really tough questions).</p>
    <p>In many cases, you can use this skill to identify monsters and
their special powers or vulnerabilities. In general, the DC of such a
check equals 10 + the monster&#8217;s HD. A successful check allows you to
remember a bit of useful information about that monster. For every 5
points by which your check result exceeds the DC, you recall another
piece of useful information.</p>
    <p><b>Action</b>: Usually none. In most cases, making a Knowledge
check doesn&#8217;t take an action&#8212;you simply know the answer or you don&#8217;t.</p>
    <p><b>Try Again</b>: No. The check represents what you know, and
thinking about a topic a second time doesn&#8217;t let you know something
that you never learned in the first place.</p>
    <p><b>Synergy</b>: If you have 5 or more ranks in Knowledge
(arcana), you get a +2 bonus on <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> checks.</p>
    <p>If you have 5 or more ranks in Knowledge (architecture and
engineering), you get a +2 bonus on <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a> checks made to find
secret doors or hidden compartments.</p>
    <p>If you have 5 or more ranks in Knowledge (geography), you get
a +2 bonus on <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> checks made to keep
from getting lost or to avoid natural hazards.</p>
    <p>If you have 5 or more ranks in Knowledge (history), you get a
+2 bonus on bardic knowledge checks.</p>
    <p>If you have 5 or more ranks in Knowledge (local), you get a +2
bonus on <a href="skillsAll.html#gather-information" style={{
        "color": "rgb(87, 158, 182)"
      }}>Gather Information</a> checks.</p>
    <p>If you have 5 or more ranks in Knowledge (nature), you get a
+2 bonus on Survival checks made in aboveground natural environments
(aquatic, desert, forest, hill, marsh, mountains, or plains).</p>
    <p>If you have 5 or more ranks in Knowledge (nobility and
royalty), you get a +2 bonus on <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> checks.</p>
    <p>If you have 5 or more ranks in Knowledge (religion), you get a
+2 bonus on turning checks against undead.</p>
    <p>If you have 5 or more ranks in Knowledge (the planes), you get
a +2 bonus on Survival checks made while on other planes.</p>
    <p>If you have 5 or more ranks in Knowledge (dungeoneering), you
get a +2 bonus on Survival checks made while underground.</p>
    <p>If you have 5 or more ranks in Survival, you get a +2 bonus on
Knowledge (nature) checks.</p>
    <p><b>Untrained</b>: An untrained Knowledge check is simply an
Intelligence check. Without actual training, you know only common
knowledge (DC 10 or lower).</p>
    <h5>OPEN LOCK (DEX; TRAINED ONLY)</h5>
    <p className="initial">Attempting an Open Lock check without a set of
thieves&#8217; tools imposes a &#8211;2 circumstance penalty on the check, even if
a simple tool is employed. If you use masterwork thieves&#8217; tools, you
gain a +2 circumstance bonus on the check.</p>
    <p><b>Check</b>: The DC for opening a lock varies from 20 to 40,
depending on the quality of the lock, as given on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>Lock</th>
          <th style={{
            "width": "35px"
          }}>DC</th>
          <th style={{
            "width": "125px"
          }}>Lock</th>
          <th style={{
            "width": "35px"
          }}>DC</th>
        </tr>
        <tr className="odd-row">
          <td>Very simple lock</td>
          <td>20</td>
          <td>Good lock</td>
          <td>30</td>
        </tr>
        <tr>
          <td className="last-row">Average lock</td>
          <td className="last-row">25</td>
          <td className="last-row">Amazing lock</td>
          <td className="last-row">40</td>
        </tr>
      </tbody>
    </table>
    <p><b>Action</b>: Opening a lock is a full-round action.</p>
    <p><b>Special</b>: If you have the <a href="featsAll.html#nimble-fingers" style={{
        "color": "rgb(87, 158, 182)"
      }}>Nimble
Fingers</a> feat, you get a +2 bonus on Open Lock checks.</p>
    <p><b>Untrained</b>: You cannot pick locks untrained, but you
might successfully force them open.</p>
    <h5>PROFESSION (WIS; TRAINED ONLY)</h5>
    <p className="initial">Like Craft, Knowledge, and Perform, Profession
is actually a number of separate skills. You could have several
Profession skills, each with its own ranks, each purchased as a
separate skill. While a Craft skill represents ability in creating or
making an item, a Profession skill represents an aptitude in a vocation
requiring a broader range of less specific knowledge. </p>
    <p><b>Check</b>: You can practice your trade and make a decent
living, earning about half your Profession check result in gold pieces
per week of dedicated work. You know how to use the tools of your
trade, how to perform the profession&#8217;s daily tasks, how to supervise
helpers, and how to handle common problems.</p>
    <p><b>Action</b>: Not applicable. A single check generally
represents a week of work.</p>
    <p><b>Try Again</b>: Varies. An attempt to use a Profession skill
to earn an income cannot be retried. You are stuck with whatever weekly
wage your check result brought you. Another check may be made after a
week to determine a new income for the next period of time. An attempt
to accomplish some specific task can usually be retried.</p>
    <p><b>Untrained</b>: Untrained laborers and assistants (that is,
characters without any ranks in Profession) earn an average of 1 silver
piece per day.</p>
    <h5>SLEIGHT OF HAND (DEX; TRAINED ONLY; ARMOR CHECK PENALTY)</h5>
    <p className="initial"><b>Check</b>: A DC 10 Sleight of Hand check
lets you palm a coin-sized, unattended object. Performing a minor feat
of legerdemain, such as making a coin disappear, also has a DC of 10
unless an observer is determined to note where the item went.</p>
    <p>When you use this skill under close observation, your skill
check is opposed by the observer&#8217;s <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> check. The observer&#8217;s
success doesn&#8217;t prevent you from performing the action, just from doing
it unnoticed.</p>
    <p>You can hide a small object (including a light weapon or an
easily concealed ranged weapon, such as a dart, sling, or hand
crossbow) on your body. Your Sleight of Hand check is opposed by the
Spot check of anyone observing you or the <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
check of anyone frisking you. In the latter case, the searcher gains a
+4 bonus on the Search check, since it&#8217;s generally easier to find such
an object than to hide it. A dagger is easier to hide than most light
weapons, and grants you a +2 bonus on your Sleight of Hand check to
conceal it. An extraordinarily small object, such as a coin, shuriken,
or ring, grants you a +4 bonus on your Sleight of Hand check to conceal
it, and heavy or baggy clothing (such as a cloak) grants you a +2 bonus
on the check. Drawing a hidden weapon is a standard action and doesn&#8217;t
provoke an attack of opportunity.</p>
    <p>If you try to take something from another creature, you must
make a DC 20 Sleight of Hand check to obtain it. The opponent makes a
Spot check to detect the attempt, opposed by the same Sleight of Hand
check result you achieved when you tried to grab the item. An opponent
who succeeds on this check notices the attempt, regardless of whether
you got the item.</p>
    <p>You can also use Sleight of Hand to entertain an audience as
though you were using the <a href="skillsAll.html#perform" style={{
        "color": "rgb(87, 158, 182)"
      }}>Perform</a> skill. In such a case,
your &#8220;act&#8221; encompasses elements of legerdemain, juggling, and the like.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "130px"
          }}>Sleight of Hand DC</th>
          <th style={{
            "width": "300px"
          }}>Task</th>
        </tr>
        <tr className="odd-row">
          <td align="center">10</td>
          <td>Palm a coin-sized object, make a coin disappear</td>
        </tr>
        <tr>
          <td className="last-row" align="center">20</td>
          <td className="last-row">Lift a small object from a person</td>
        </tr>
      </tbody>
    </table>
    <p><b>Action</b>: Any Sleight of Hand check normally is a
standard action. However, you may perform a Sleight of Hand check as a
free action by taking a &#8211;20 penalty on the check.</p>
    <p><b>Try Again</b>: Yes, but after an initial failure, a second
Sleight of Hand attempt against the same target (or while you are being
watched by the same observer who noticed your previous attempt)
increases the DC for the task by 10.</p>
    <p><b>Special</b>: If you have the <a href="featsAll.html#deft-hands" style={{
        "color": "rgb(87, 158, 182)"
      }}>Deft
Hands</a> feat, you get a +2 bonus on Sleight of Hand checks.</p>
    <p><b>Synergy</b>: If you have 5 or more ranks in <a href="skillsAll.html#bluff" style={{
        "color": "rgb(87, 158, 182)"
      }}>Bluff</a>,
you get a +2 bonus on Sleight of Hand checks.</p>
    <p><b>Untrained</b>: An untrained Sleight of Hand check is simply
a Dexterity check. Without actual training, you can&#8217;t succeed on any
Sleight of Hand check with a DC higher than 10, except for hiding an
object on your body.</p>
    <h5>SPEAK LANGUAGE (NONE; TRAINED ONLY)</h5>
    <p className="initial"><b>Table: Common Languages and Their Alphabets</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Language</th>
          <th style={{
            "width": "250px"
          }}>Typical Speakers</th>
          <th>Alphabet</th>
        </tr>
        <tr className="odd-row">
          <td>Abyssal</td>
          <td>Demons, chaotic evil outsiders</td>
          <td>Infernal</td>
        </tr>
        <tr>
          <td>Aquan</td>
          <td>Water-based creatures</td>
          <td>Elven</td>
        </tr>
        <tr className="odd-row">
          <td>Auran</td>
          <td>Air-based creatures</td>
          <td>Draconic</td>
        </tr>
        <tr>
          <td>Celestial</td>
          <td>Good outsiders</td>
          <td>Celestial</td>
        </tr>
        <tr className="odd-row">
          <td>Common</td>
          <td>Humans, halflings, half-elves, half-orcs</td>
          <td>Common</td>
        </tr>
        <tr>
          <td>Draconic</td>
          <td>Kobolds, troglodytes, lizardfolk, dragons</td>
          <td>Draconic</td>
        </tr>
        <tr className="odd-row">
          <td>Druidic</td>
          <td>Druids (only)</td>
          <td>Druidic</td>
        </tr>
        <tr>
          <td>Dwarven</td>
          <td>Dwarves</td>
          <td>Dwarven</td>
        </tr>
        <tr className="odd-row">
          <td>Elven</td>
          <td>Elves</td>
          <td>Elven</td>
        </tr>
        <tr>
          <td>Giant</td>
          <td>Ogres, giants</td>
          <td>Dwarven</td>
        </tr>
        <tr className="odd-row">
          <td>Gnome</td>
          <td>Gnomes</td>
          <td>Dwarven</td>
        </tr>
        <tr>
          <td>Goblin</td>
          <td>Goblins, hobgoblins, bugbears</td>
          <td>Dwarven</td>
        </tr>
        <tr className="odd-row">
          <td>Gnoll</td>
          <td>Gnolls</td>
          <td>Common</td>
        </tr>
        <tr>
          <td>Halfling</td>
          <td>Halflings</td>
          <td>Common</td>
        </tr>
        <tr className="odd-row">
          <td>Ignan</td>
          <td>Fire-based creatures</td>
          <td>Draconic</td>
        </tr>
        <tr>
          <td>Infernal</td>
          <td>Devils, lawful evil outsiders</td>
          <td>Infernal</td>
        </tr>
        <tr className="odd-row">
          <td>Orc</td>
          <td>Orcs</td>
          <td>Dwarven</td>
        </tr>
        <tr>
          <td>Sylvan</td>
          <td>Dryads, brownies, leprechauns</td>
          <td>Elven</td>
        </tr>
        <tr className="odd-row">
          <td>Terran</td>
          <td>Xorns and other earth-based creatures</td>
          <td>Dwarven</td>
        </tr>
        <tr>
          <td className="last-row">Undercommon</td>
          <td className="last-row">Drow</td>
          <td className="last-row">Elven</td>
        </tr>
      </tbody>
    </table>
    <p><b>Action</b>: Not applicable.</p>
    <p><b>Try Again</b>: Not applicable. There are no Speak Language
checks to fail.</p>
    <p>The Speak Language skill doesn&#8217;t work like other skills.
Languages work as follows.</p>
    <ul>
      <li>You start at 1st level knowing one or two languages (based
on your race), plus an additional number of languages equal to your
starting Intelligence bonus.</li>
      <li>You can purchase Speak Language just like any other skill,
but instead of buying a rank in it, you choose a new language that you
can speak.</li>
      <li>You don&#8217;t make Speak Language checks. You either know a
language or you don&#8217;t.</li>
      <li>A literate character (anyone but a barbarian who has not
spent skill points to become literate) can read and write any language
she speaks. Each language has an alphabet, though sometimes several
spoken languages share a single alphabet.</li>
    </ul>
    <h5>SPELLCRAFT (INT; TRAINED ONLY)</h5>
    <p className="initial">Use this skill to identify spells as they are
cast or spells already in place.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "110px"
          }}>Spellcraft DC</th>
          <th>Task</th>
        </tr>
        <tr className="odd-row">
          <td>13</td>
          <td>When using read magic, identify a glyph of warding. No
action required.</td>
        </tr>
        <tr>
          <td>15 + spell level</td>
          <td>Identify a spell being cast. (You must see or hear the
spell&#8217;s verbal or somatic components.) No action required. No retry. </td>
        </tr>
        <tr className="odd-row">
          <td>15 + spell level</td>
          <td>Learn a spell from a spellbook or scroll (wizard only).
No retry for that spell until you gain at least 1 rank in Spellcraft
(even if you find another source to try to learn the spell from).
Requires 8 hours.</td>
        </tr>
        <tr>
          <td>15 + spell level</td>
          <td>Prepare a spell from a borrowed spellbook (wizard
only). One try per day. No extra time required. </td>
        </tr>
        <tr className="odd-row">
          <td>15 + spell level</td>
          <td>When casting detect magic, determine the school of
magic involved in the aura of a single item or creature you can see.
(If the aura is not a spell effect, the DC is 15 + one-half caster
level.) No action required.</td>
        </tr>
        <tr>
          <td>19</td>
          <td>When using read magic, identify a symbol. No action
required.</td>
        </tr>
        <tr className="odd-row">
          <td>20 + spell level</td>
          <td>Identify a spell that&#8217;s already in place and in effect.
You must be able to see or detect the effects of the spell. No action
required. No retry.</td>
        </tr>
        <tr>
          <td>20 + spell level</td>
          <td>Identify materials created or shaped by magic, such as
noting that an iron wall is the result of a wall of iron spell. No
action required. No retry.</td>
        </tr>
        <tr className="odd-row">
          <td>20 + spell level</td>
          <td>Decipher a written spell (such as a scroll) without
using read magic. One try per day. Requires a full-round action.</td>
        </tr>
        <tr>
          <td>25 + spell level</td>
          <td>After rolling a saving throw against a spell targeted
on you, determine what that spell was. No action required. No retry.</td>
        </tr>
        <tr className="odd-row">
          <td>25</td>
          <td>Identify a potion. Requires 1 minute. No retry.</td>
        </tr>
        <tr>
          <td>20</td>
          <td>Draw a diagram to allow dimensional anchor to be cast
on a magic circle spell. Requires 10 minutes. No retry. This check is
made secretly so you do not know the result.</td>
        </tr>
        <tr className="odd-row">
          <td>30 or higher</td>
          <td>Understand a strange or unique magical effect, such as
the effects of a magic stream. Time required varies. No retry.</td>
        </tr>
      </tbody>
    </table>
    <p><b>Check</b>: You can identify spells and magic effects. The
DCs for Spellcraft checks relating to various tasks are summarized on
the table above.</p>
    <p><b>Action</b>: Varies, as noted above.</p>
    <p><b>Try Again</b>: See above.</p>
    <p><b>Special</b>: If you are a specialist wizard, you get a +2
bonus on Spellcraft checks when dealing with a spell or effect from
your specialty school. You take a &#8211;5 penalty when dealing with a spell
or effect from a prohibited school (and some tasks, such as learning a
prohibited spell, are just impossible).</p>
    <p>If you have the <a href="featsAll.html#magical-aptitude" style={{
        "color": "rgb(87, 158, 182)"
      }}>Magical Aptitude</a> feat, you get a
+2 bonus on Spellcraft checks.</p>
    <p><b>Synergy</b>: If you have 5 or more ranks in <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a>
(arcana), you get a +2 bonus on Spellcraft checks.</p>
    <p>If you have 5 or more ranks in <a href="skillsAll.html#use-magic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Magic Device</a>, you get a +2
bonus on Spellcraft checks to decipher spells on scrolls.</p>
    <p>If you have 5 or more ranks in Spellcraft, you get a +2 bonus
on Use Magic Device checks related to scrolls.</p>
    <p>Additionally, certain spells allow you to gain information
about magic, provided that you make a successful Spellcraft check as
detailed in the spell description.</p>
    <h5>TUMBLE (DEX; TRAINED ONLY; ARMOR CHECK PENALTY)</h5>
    <p className="initial">You can&#8217;t use this skill if your speed has
been reduced by armor, excess equipment, or loot.</p>
    <p><b>Check</b>: You can land softly when you fall or tumble past
opponents. You can also tumble to entertain an audience (as though
using the Perform skill). The DCs for various tasks involving the
Tumble skill are given on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "75px"
          }}>Tumble DC</th>
          <th>Task</th>
        </tr>
        <tr className="odd-row">
          <td align="center">15</td>
          <td>Treat a fall as if it were 10 feet shorter than it
really is when determining damage.</td>
        </tr>
        <tr>
          <td align="center">15</td>
          <td>Tumble at one-half speed as part of normal movement,
provoking no attacks of opportunity while doing so. Failure means you
provoke attacks of opportunity normally. Check separately for each
opponent you move past, in the order in which you pass them (player&#8217;s
choice of order in case of a tie). Each additional enemy after the
first adds +2 to the Tumble DC.</td>
        </tr>
        <tr className="odd-row">
          <td align="center">25</td>
          <td>Tumble at one-half speed through an area occupied by an
enemy (over, under, or around the opponent) as part of normal movement,
provoking no attacks of opportunity while doing so. Failure means you
stop before entering the enemy-occupied area and provoke an attack of
opportunity from that enemy. Check separately for each opponent. Each
additional enemy after the first adds +2 to the Tumble DC.</td>
        </tr>
      </tbody>
    </table>
    <p>Obstructed or otherwise treacherous surfaces, such as natural
cavern floors or undergrowth, are tough to tumble through. The DC for
any Tumble check made to tumble into such a square is modified as
indicated below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "475px"
          }}>Surface Is . . .</th>
          <th>DC Modifier</th>
        </tr>
        <tr className="odd-row">
          <td>Lightly obstructed (scree, light rubble, shallow bog<sup>1</sup>,
undergrowth)</td>
          <td align="center">+2</td>
        </tr>
        <tr>
          <td>Severely obstructed (natural cavern floor, dense
rubble, dense undergrowth)</td>
          <td align="center">+5</td>
        </tr>
        <tr className="odd-row">
          <td>Lightly slippery (wet floor)</td>
          <td align="center">+2</td>
        </tr>
        <tr>
          <td>Severely slippery (ice sheet)</td>
          <td align="center">+5</td>
        </tr>
        <tr className="odd-row">
          <td>Sloped or angled</td>
          <td align="center">+2</td>
        </tr>
        <tr>
          <td colSpan="2">1 <i>Tumbling is impossible in a deep bog.</i></td>
        </tr>
      </tbody>
    </table>
    <p><i>Accelerated Tumbling</i>: You try to tumble past or through
enemies more quickly than normal. By accepting a &#8211;10 penalty on your
Tumble checks, you can move at your full speed instead of one-half your
speed.</p>
    <p><b>Action</b>: Not applicable. Tumbling is part of movement,
so a Tumble check is part of a move action.</p>
    <p><b>Try Again</b>: Usually no. An audience, once it has judged
a tumbler as an uninteresting performer, is not receptive to repeat
performances. You can try to reduce damage from a fall as an instant
reaction only once per fall.</p>
    <p><b>Special</b>: If you have 5 or more ranks in Tumble, you
gain a +3 dodge bonus to AC when fighting defensively instead of the
usual +2 dodge bonus to AC.</p>
    <p>If you have 5 or more ranks in Tumble, you gain a +6 dodge
bonus to AC when executing the total defense standard action instead of
the usual +4 dodge bonus to AC.</p>
    <p>If you have the <a href="featsAll.html#acrobatic" style={{
        "color": "rgb(87, 158, 182)"
      }}>Acrobatic</a> feat, you get a +2
bonus on Tumble checks.</p>
    <p><b>Synergy</b>: If you have 5 or more ranks in Tumble, you get
a +2 bonus on <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a> and <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
checks.</p>
    <p>If you have 5 or more ranks in Jump, you get a +2 bonus on
Tumble checks.</p>
    <h5>USE MAGIC DEVICE (CHA; TRAINED ONLY)</h5>
    <p className="initial">Use this skill to activate magic</p>
    <p><b>Check</b>: You can use this skill to read a spell or to
activate a magic item. Use Magic Device lets you use a magic item as if
you had the spell ability or class features of another class, as if you
were a different race, or as if you were of a different alignment.</p>
    <p>You make a Use Magic Device check each time you activate a
device such as a wand. If you are using the check to emulate an
alignment or some other quality in an ongoing manner, you need to make
the relevant Use Magic Device check once per hour.</p>
    <p>You must consciously choose which requirement to emulate. That
is, you must know what you are trying to emulate when you make a Use
Magic Device check for that purpose. The DCs for various tasks
involving Use Magic Device checks are summarized on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>Task</th>
          <th>Use Magic Device DC</th>
        </tr>
        <tr className="odd-row">
          <td>Activate blindly</td>
          <td align="center">25</td>
        </tr>
        <tr>
          <td>Decipher a written spell</td>
          <td align="center">25 + spell level</td>
        </tr>
        <tr className="odd-row">
          <td>Use a scroll</td>
          <td align="center">20 + caster level</td>
        </tr>
        <tr>
          <td>Use a wand</td>
          <td align="center">20</td>
        </tr>
        <tr className="odd-row">
          <td>Emulate a class feature</td>
          <td align="center">20</td>
        </tr>
        <tr>
          <td>Emulate an ability score</td>
          <td align="center">See text</td>
        </tr>
        <tr className="odd-row">
          <td>Emulate a race</td>
          <td align="center">25</td>
        </tr>
        <tr>
          <td className="last-row">Emulate an alignment</td>
          <td className="last-row" align="center">30</td>
        </tr>
      </tbody>
    </table>
    <p><i>Activate Blindly</i>: Some magic items are activated by
special words, thoughts, or actions. You can activate such an item as
if you were using the activation word, thought, or action, even when
you&#8217;re not and even if you don&#8217;t know it. You do have to perform some
equivalent activity in order to make the check. That is, you must
speak, wave the item around, or otherwise attempt to get it to
activate. You get a special +2 bonus on your Use Magic Device check if
you&#8217;ve activated the item in question at least once before. If you fail
by 9 or less, you can&#8217;t activate the device. If you fail by 10 or more,
you suffer a mishap. A mishap means that magical energy gets released
but it doesn&#8217;t do what you wanted it to do. The default mishaps are
that the item affects the wrong target or that uncontrolled magical
energy is released, dealing 2d6 points of damage to you. This mishap is
in addition to the chance for a mishap that you normally run when you
cast a spell from a scroll that you could not otherwise cast yourself.</p>
    <p><i>Decipher a Written Spell</i>: This usage works just like
deciphering a written spell with the Spellcraft skill, except that the
DC is 5 points higher. Deciphering a written spell requires 1 minute of
concentration.</p>
    <p><i>Emulate an Ability Score</i>: To cast a spell from a
scroll, you need a high score in the appropriate ability (Intelligence
for wizard spells, Wisdom for divine spells, or Charisma for sorcerer
or bard spells). Your effective ability score (appropriate to the class
you&#8217;re emulating when you try to cast the spell from the scroll) is
your Use Magic Device check result minus 15. If you already have a high
enough score in the appropriate ability, you don&#8217;t need to make this
check.</p>
    <p><i>Emulate an Alignment</i>: Some magic items have positive or
negative effects based on the user&#8217;s alignment. Use Magic Device lets
you use these items as if you were of an alignment of your choice. You
can emulate only one alignment at a time.</p>
    <p><i>Emulate a Class Feature</i>: Sometimes you need to use a
class feature to activate a magic item. In this case, your effective
level in the emulated class equals your Use Magic Device check result
minus 20. This skill does not let you actually use the class feature of
another class. It just lets you activate items as if you had that class
feature. If the class whose feature you are emulating has an alignment
requirement, you must meet it, either honestly or by emulating an
appropriate alignment with a separate Use Magic Device check (see
above).</p>
    <p><i>Emulate a Race</i>: Some magic items work only for members
of certain races, or work better for members of those races. You can
use such an item as if you were a race of your choice. You can emulate
only one race at a time.</p>
    <p><i>Use a Scroll</i>: If you are casting a spell from a scroll,
you have to decipher it first. Normally, to cast a spell from a scroll,
you must have the scroll&#8217;s spell on your class spell list. Use Magic
Device allows you to use a scroll as if you had a particular spell on
your class spell list. The DC is equal to 20 + the caster level of the
spell you are trying to cast from the scroll. In addition, casting a
spell from a scroll requires a minimum score (10 + spell level) in the
appropriate ability. If you don&#8217;t have a sufficient score in that
ability, you must emulate the ability score with a separate Use Magic
Device check (see above).</p>
    <p>This use of the skill also applies to other spell completion
magic items.</p>
    <p><i>Use a Wand</i>: Normally, to use a wand, you must have the
wand&#8217;s spell on your class spell list. This use of the skill allows you
to use a wand as if you had a particular spell on your class spell
list. This use of the skill also applies to other spell trigger magic
items, such as staffs.</p>
    <p><b>Action</b>: None. The Use Magic Device check is made as
part of the action (if any) required to activate the magic item.</p>
    <p><b>Try Again</b>: Yes, but if you ever roll a natural 1 while
attempting to activate an item and you fail, then you can&#8217;t try to
activate that item again for 24 hours.</p>
    <p><b>Special</b>: You cannot take 10 with this skill.</p>
    <p>You can&#8217;t aid another on Use Magic Device checks. Only the
user of the item may attempt such a check.</p>
    <p>If you have the <a href="featsAll.html#magical-aptitude" style={{
        "color": "rgb(87, 158, 182)"
      }}>Magical Aptitude</a> feat, you get a
+2 bonus on Use Magic Device checks.</p>
    <p><b>Synergy</b>: If you have 5 or more ranks in <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>,
you get a +2 bonus on Use Magic Device checks related to scrolls.</p>
    <p>If you have 5 or more ranks in <a href="skillsAll.html#decipher-script" style={{
        "color": "rgb(87, 158, 182)"
      }}>Decipher
Script</a>, you get a +2 bonus on Use Magic Device checks related to
scrolls.</p>
    <p>If you have 5 or more ranks in Use Magic Device, you get a +2
bonus to Spellcraft checks made to decipher spells on scrolls.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      